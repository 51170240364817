import type { TippyProps } from '@tippyjs/react';

import Tippy from '@tippyjs/react';
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useSignupFormContext } from '../../context';
import classes from '../SignupForm/style.module.scss';

// Create a separate component for the Tooltip content
const tooltipContentMapping = {
  'modules.changelog': {
    imgSrc: '/public/img/dash/onboarding/changelog-tooltip.png',
    text: 'Changelog',
    subtext: 'A place to share API changes where developers can subscribe for updates',
  },
  'modules.discuss': {
    imgSrc: '/public/img/dash/onboarding/discussions-tooltip.png',
    text: 'Discussions',
    subtext: 'A community forum where your users have a central place to ask questions',
  },
  'modules.reference': {
    imgSrc: '/public/img/dash/onboarding/reference-tooltip.png',
    text: 'API Reference',
    subtext: 'A place to list all the endpoints and parameters for your API',
  },
  'modules.docs': {
    imgSrc: '/public/img/dash/onboarding/guides-tooltip.png',
    text: 'Guides',
    subtext: 'Your API documentation with markdown, images, and more',
  },
  'modules.tutorials': {
    imgSrc: '/public/img/dash/onboarding/recipes-tooltip.png',
    text: 'Recipes',
    subtext: 'Step-by-step walkthroughs for your API’s most common use cases',
  },
  'modules.landing': {
    imgSrc: '/public/img/dash/onboarding/landing-tooltip.png',
    text: 'Home',
    subtext: 'A customizable landing page to welcome users to your docs',
  },
};

const TooltipContent = ({ name }: { name: string }) => {
  const content = tooltipContentMapping[name] || {
    imgSrc: 'default-image.png', // Default image if name not found
    text: <div>No content available.</div>,
  };

  return (
    <Menu style={{ maxWidth: '250px' }} theme="light">
      <MenuItem focusable={false} img={{ alt: content.text, src: content.imgSrc, border: true }} />
      <MenuItem description={content.subtext} focusable={false}>
        {content.text}
      </MenuItem>
    </Menu>
  );
};

interface Props {
  label: string;
  name:
    | 'modules.changelog'
    | 'modules.discuss'
    | 'modules.docs'
    | 'modules.landing'
    | 'modules.reference'
    | 'modules.tutorials';
  singleton?: TippyProps['singleton'];
}

const iconMapping = {
  'modules.changelog': 'megaphone',
  'modules.discuss': 'message-circle',
  'modules.docs': 'book-open',
  'modules.reference': 'code',
  'modules.tutorials': 'recipes',
  'modules.landing': 'home',
};

const NavToggleField = ({ name, label, singleton }: Props) => {
  const bem = useClassy(classes, 'SignupForm');
  const { control } = useSignupFormContext();

  return (
    <RHFGroup className={`${bem('-nav-toggle-field-zero-margin')}`} control={control} name={name}>
      {({ field }) => {
        const isActive = !!field.value;

        // Dynamically determine the className
        const navToggleFieldClass = isActive ? bem('-nav-toggle-field-active') : bem('-nav-toggle-field');
        const iconClass = isActive ? bem('-icon-active') : bem('-icon');

        return (
          <div
            aria-pressed={field.value}
            className={navToggleFieldClass}
            onClick={() => field.onChange(!field.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                field.onChange(!field.value);
              }
            }}
            role="button"
            tabIndex={0}
          >
            <Toggle
              {...field}
              checked={field.value}
              className={bem('-toggle')}
              data-testid={name}
              style={{ display: 'none' }}
              type="checkbox"
              value=""
            />
            <Tippy content={<TooltipContent name={name} />} singleton={singleton}>
              {
                <div className={bem('-nav-toggle-field-custom')}>
                  <div className={bem('-label-icon')}>
                    <Icon className={iconClass} name={isActive ? 'check' : iconMapping[name]}></Icon>
                    <span className={bem('-label')}>{label}</span>
                  </div>
                </div>
              }
            </Tippy>
          </div>
        );
      }}
    </RHFGroup>
  );
};

export default NavToggleField;
