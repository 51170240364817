import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APISectionHeader from '@ui/API/SectionHeader';
import Button from '@ui/Button';
import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import { LanguageIcon } from '@ui/LanguagePicker';

import styles from './style.module.scss';

interface Props {
  method: string;
  slug: string;
}

const MockPlayground = ({ method, slug }: Props) => {
  const bem = useClassy(styles, 'MockHub');

  return (
    <Flex gap="sm" layout="col">
      <section>
        <APISectionHeader heading="Language" />
        <Flex>
          <div className={bem('-language-picker')}>
            <div className={bem('-language-picker-languages')}>
              <button className={bem('-language-picker-button', '-language-picker-button_active')} type="button">
                <LanguageIcon language="shell" />
                Shell
              </button>
              <button className={bem('-language-picker-button')} type="button">
                <LanguageIcon language="node" />
                Node
              </button>
              <button className={bem('-language-picker-button')} type="button">
                <LanguageIcon language="ruby" />
                Ruby
              </button>
              <button className={bem('-language-picker-button')} type="button">
                <LanguageIcon language="php" />
                Node
              </button>
              <button className={bem('-language-picker-button')} type="button">
                <LanguageIcon language="python" />
                Python
              </button>

              <Button className={bem('-language-picker-more')} ghost>
                <Icon aria-label="More ellipsis" name="more-vertical" />
              </Button>
            </div>
          </div>
        </Flex>
      </section>

      <section className={bem('-auth')}>
        <APISectionHeader heading="Authorization" />

        <Flex>
          <InputGroup columnLayout="auto" separators size="sm">
            <Button text>Header</Button>
            <Input placeholder="api_key" />
          </InputGroup>
        </Flex>
      </section>

      <section className={bem('-code-snippet')}>
        <Flex gap={0} layout="col">
          <Flex className={bem('-code-snippet-header')} justify="between">
            <span className={bem('-code-snippet-title')}>Curl</span>
            <Button className={bem('-code-snippet-toggle')} ghost kind="contrast" size="xs" title="Toggle library">
              <Icon name="chevron-down" />
            </Button>
          </Flex>
          <Flex className={bem('-code-snippet-code')} gap="sm" layout="col">
            <span className={bem('-code-snippet-title')}>Request</span>
            <Flex className={bem('-code-snippet-code-inner', 'cm-s-material-palenight')} gap={0} layout="col">
              <Flex gap="xs">
                <span className={bem('-code-snippet-code-line-number')}>1</span>
                <span className="cm-builtin">curl</span> <span className="cm-attribute">--request</span> {method} \
              </Flex>
              <Flex gap="xs">
                <span className={bem('-code-snippet-code-line-number')}>2</span>
                <span className="cm-attribute" style={{ marginLeft: '35px' }}>
                  --url
                </span>{' '}
                <span>{`http://readme.io/v1/${slug}`}</span>
              </Flex>
            </Flex>
          </Flex>

          <Flex align="center" className={bem('-code-snippet-footer')} gap="xs">
            <CopyToClipboard className={bem('-code-snippet-copy')} shift="end" text="" />
            <Button>Try It!</Button>
          </Flex>
        </Flex>
      </section>

      <section className={bem('-response-tryit')}>
        <Flex align="center" className={bem('-response-tryit-header')} gap="md" justify="between" tag="header">
          Response
        </Flex>
        <Flex align="center" className={bem('-response-tryit-empty')} gap="md" justify="between">
          <div>
            Click <code className={bem('-response-tryit-empty-text')}>Try It!</code> to start a request and see the
            response here!
          </div>
        </Flex>
      </section>
    </Flex>
  );
};

export default MockPlayground;
