import React from 'react';

import useClassy from '@core/hooks/useClassy';

import FancyCard from '@ui/Onboarding/FancyCard';

import SignupFormProvider from '../context';
import TermsAndConditions from '../TermsAndConditions';

import styles from './style.module.scss';

const backgroundSettings = {
  background: 'linear-gradient(var(--blue), var(--blue30))',
  orbAlpha: 0.15,
  orbColors: ['yellow', 'red', 'green'],
  showWhiteBoxShadow: false,
};

interface Props {
  children: React.ReactNode;
  /** Whether to render a single card at full width (only relevant when Mock Hub is included) */
  isSingleCard?: boolean;
  /** A Mock Hub component to render in the layout */
  mockHub?: React.ReactNode;
  /** Whether to show grid-like pattern against background */
  showBackgroundGrid?: boolean;
  /** Whether to show terms and conditions at bottom of card */
  showTermsAndConditions?: boolean;
}

const OnboardingLayout = ({
  children,
  mockHub,
  isSingleCard = false,
  showBackgroundGrid = false,
  showTermsAndConditions = false,
}: Props) => {
  const bem = useClassy(styles, 'OnboardingLayout');

  return (
    <div className={bem('&')}>
      <FancyCard
        className={bem('-fancy-card', showBackgroundGrid && '-fancy-card_show-background-grid')}
        contentClassName={bem('-fancy-card-content')}
        orbOffset={[0, 15]}
        orbPlacement="right-start"
        orbSize={100}
        padding={0}
        {...backgroundSettings}
      >
        <div className={bem('-layout', isSingleCard && '-layout_single-card')}>
          <SignupFormProvider>
            <div className={bem('-card-container')}>
              <a href="https://readme.com">
                <img alt="ReadMe" className={bem('-logo')} src="/public/img/readme-white.svg" />
              </a>
              {children}

              {!!showTermsAndConditions && <TermsAndConditions />}
            </div>

            {!!mockHub && <div className={bem('-mock-hub-container')}>{mockHub}</div>}
          </SignupFormProvider>
        </div>
      </FancyCard>
    </div>
  );
};

export default OnboardingLayout;
