import { useState, useEffect, useRef } from 'react';

/**
 * @param {Oas} apiDefinition
 */
export default function useDereference(apiDefinition) {
  const errorRef = useRef(null);
  const [isDereferenced, setIsDereferenced] = useState(apiDefinition?.dereferencing?.complete || false);

  useEffect(() => {
    let isCanceled = false;

    async function dereference() {
      try {
        await apiDefinition.dereference();
        errorRef.current = null;
      } catch (e) {
        errorRef.current = e;
      }
      if (!isCanceled) setIsDereferenced(true);
    }

    if (!isDereferenced) dereference();

    return () => {
      isCanceled = true;
    };
  }, [isDereferenced, apiDefinition?.api?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isDereferenced, apiDefinition, error: errorRef.current };
}
